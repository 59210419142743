import api from '../backend-api.js';

function pagination(url, options) {
  if (options) {
    if (options.itemsPerPage > 0) {
      url += 'size=' + options.itemsPerPage + '&';
      url += 'page=' + (options.page ?? 1) + '&';
    } else if (options.totalItems) {
      url += 'size=' + options.totalItems + '&';
      url += 'page=1&';
    }
    if (options.sortBy && options.sortBy.length > 0) {
      options.sortBy.forEach((sort, index) => {
        url += 'sort=' + sort + ',' + (options.sortDesc[index] ? 'desc' : 'asc') + '&';
      })
    }
  }
  url = url.substring(0, url.length - 1);
  return url;
}

export default {
  async getAllContainerModels(options) {
    let url = '/container/model?'
    url = pagination(url, options);
    return (await api.getData(url)).data;
  },
  async getContainerModelById(id) {
    let url = '/container/model/' + id
    return (await api.getData(url)).data;
  },
  async createContainerModel(containerModel) {
    let url = '/container/model'
    return (await api.postData(url, containerModel)).data;
  },
  async updateContainerModel(containerModel) {
    let url = '/container/model/' + containerModel.id;
    return (await api.putData(url, containerModel)).data;
  },

};

