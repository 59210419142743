const state = {
  drawer: true,
  breadcrumbs: [],
  snackbar: {},
  notice: '',
}

const getters = {}

const mutations = {
  /* SET_BAR_IMAGE (state, payload) {
    state.barImage = payload
  }, */
  set_snackbar (state, payload) {
    state.snackbar = payload
  },
  set_drawer (state, payload) {
    state.drawer = payload
  },
  set_breadcrumbs (state, breadcrumbs) {
    state.breadcrumbs = breadcrumbs
  },
  push_breadcrumbs (state, breadcrumb) {
      state.breadcrumbs.push(breadcrumb)
  },
  pop_breadcrumbs (state) {
      state.breadcrumbs.pop()
  },
  replace_breadcrumbs (state, payload) {
    const index = state.breadcrumbs.findIndex((breadcrumb) => {
        return breadcrumb.text === payload.find
    })

    if (index) {
        state.breadcrumbs.splice(index, 1, payload.replace)
    }
  },
  empty_breadcrumbs (state) {
      state.breadcrumbs = []
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
}
