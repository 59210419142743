import Vue from 'vue'
import Vuex from 'vuex'
import apiMachine from '../../utils/backend/machine'

Vue.use(Vuex)

const state = {

  callingAPI: false,
  searching: '',
  user: {
    accessToken: null,
    selectedMachineID: null,
    selectedFacilityId: null,
  },
  loggedUserInfo: {
    messages: [],
    notifications: [],
    tasks: [],
  },
  machines: [],
  activeMachines: []
}

const actions = {
  async updateUser({ commit }, { user }) {
    commit('setUser', user)
  },
  async setSelectedMachineID({ commit }, selectedMachineID) {
    console.log("loggeduser/setSelectedMachineID", selectedMachineID)
    return await commit('setSelectedMachineID', { selectedMachineID })
  },
  async setSelectedFacilityId({ commit }, selectedFacilityId) {
    console.log("loggeduser/setSelectedFacilityId", selectedFacilityId)
    return await commit('setSelectedFacilityId', { selectedFacilityId })
  },
  async logout({ commit }) {
    await commit('setUser', {})
  },
  async getAllMachinesForUser({ commit }) {
    let machines = await apiMachine.getAll();
    let activeMachines = machines.filter(machine => machine.active).sort((m1, m2) => m1.name.localeCompare(m2.name))
    if (machines) {
      await commit('setMachines', machines.sort((m1, m2) => m1.name.localeCompare(m2.name)));
      await commit('setActiveMachines', activeMachines)
      if (machines.length > 0) {
        console.log("setting selectedMachines according to active machines");
        return await commit('setSelectedMachineID', { selectedMachineID: activeMachines[0].id, override: false })
      }
    }
  },
}

const mutations = {
  initializeStore(state) {
    if ((state.user === null || state.user.accessToken === null) && localStorage.getItem('logged-user')) {
      state.user = JSON.parse(localStorage.getItem('logged-user'));
    }
  },
  loginLoading(state) {
    state.callingAPI = !state.callingAPI
  },
  globalSearching(state) {
    state.searching = state.searching === '' ? 'loading' : ''
  },
  setUser(state, user) {
    state.user = user;
    localStorage.setItem('logged-user', JSON.stringify(user));
  },
  setLoggedUserInfo(state, loggedUserInfo) {
    state.loggedUserInfo = loggedUserInfo
  },
  setMachines(state, machines) {
    state.machines = machines
  },
  setActiveMachines(state, machines) {
    state.activeMachines = machines
  },
  setSelectedMachineID(state, { selectedMachineID, override = true }) {
    if (override || state.user.selectedMachineID === null || typeof state.user.selectedMachineID === 'undefined') {
      console.log(selectedMachineID, override);
      state.user.selectedMachineID = selectedMachineID
    }
  },
  setSelectedFacilityId(state, { selectedFacilityId, override = true }) {
    if (override || state.user.selectedFacilityId === null || typeof state.user.selectedFacilityId === 'undefined') {
      console.log(selectedFacilityId, override);
      state.user.selectedFacilityId = selectedFacilityId
    }
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
