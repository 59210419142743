import api from '../../utils/backend-api'
import apiUser from '@/utils/backend/user.js'
import { User } from '../../models/User'

// import { get } from 'lodash'

const state = {
  user: new User(),
  items: undefined,
}

const getters = {}

const actions = {
  async getAllUsers({ commit }) {
    let users = await apiUser.getAllUsers();
    users.forEach(user => {
      user.role = user.roles.map(role => role.id).sort()[user.roles.length - 1];
    });
    await commit('setItems', users)
  },
  async getAllFacilities({ commit }) {
    let facilities = await apiUser.getAllFacilities();
    commit('setFacilities', facilities);
  },
  getUserById({ commit }, id) {
    commit('setLoading', { loading: true })
    if (id) {
      api.getData('/user/' + id).then(
        res => {
          const user = res.data
          commit('setUser', user)
          commit('breadcrumbs/replace',
            {
              find: ':user',
              replace: { text: user.name },
            },
            { root: true },
          )
        },
        // err => {
        //   console.log(err)
        // },
      )
    } else {
      commit('setUser', { user: new User() })
    }
  },
}

const mutations = {
  setItems(state, users) {
    state.items = users
  },
  setFacilities(state, facilities) {
    state.facilities = facilities; 
  },
  setUser(state, user) {
    state.user = user
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
