const state = {
  items: [],
}

const getters = {}

const mutations = {
  set (state, items) {
    state.items = items
  },
  push (state, item) {
      state.items.push(item)
  },
  pop (state) {
      state.items.pop()
  },
  replace (state, payload) {
    const index = state.items.findIndex((item) => {
        return item.text === payload.find
    })

    if (index) {
        state.items.splice(index, 1, payload.replace)
    }
  },
  empty (state) {
      state.items = []
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
}
