export class User {
  constructor () {
    this.id = '';
    this.firstname = '';
    this.lastname = '';
    this.password = '';
    this.username = '';
    this.facilityIds = [];
    this.companyId = '';
    this.lastConnection = '';
    this.mail = '';
  }
}