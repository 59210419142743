import moment from "moment";
import Vue from 'vue';
import Vuex from 'vuex';
import api from '../../utils/backend-api';
import unitConversion from '@/utils/unit-conversion';
import formatText from '@/utils/mixins/formatText';
import EmptyMachineHistory from "../../views/noww/machine/components/EmptyMachineHistory.vue";

Vue.use(Vuex)

const state = {
  machineStatus: {},
  drawers: [],
  drawersLabels: [],
  lastGlassDepositDate: null,
  history: [],
  deposit: {},
  machineStatusAtTimestamp: {},
  drawersAtTimestamp : [],
  drawersLabelsAtTimestamp: []
}

const historyEntries = {
  emptying_all: {
    title: 'Vidage complet',
    hide: () => { return false },
    formatMessage: () => { },
    component: EmptyMachineHistory
  },
  emptying_plastic: {
    title: 'Vidage du plastique',
    hide: () => { return true }, formatMessage: entry => { return entry.message; }
  },
  emptying_glass: {
    title: 'Vidage du verre',
    hide: () => { return true }, formatMessage: entry => { return entry.message; }
  },
  emptying_bottle: {
    title: 'Vidage des bouteilles',
    hide: () => { return true }, formatMessage: entry => { return entry.message; }
  },
  maintenance_access: {
    title: 'Accès au mode maintenance',
    hide: () => { return false }, formatMessage: entry => { return entry.message; }
  },
  program_exit: {
    title: 'Programme kiosque arrêté',
    hide: () => { return false }, formatMessage: entry => { return entry.message; }
  },
  program_start: {
    title: 'Programme kiosque démarré',
    hide: () => { return false }, formatMessage: entry => { return entry.message; }
  },
  cleaning_done: {
    title: 'Machine nettoyée',
    hide: () => { return false }, formatMessage: entry => { return entry.message; }
  },
  printer_error: {
    title: 'Erreur imprimante',
    hide: () => { return false }, formatMessage: entry => { return entry.message; }
  },
  sensor_obstruct_error: {
    title: "Passage inattendu",
    hide: () => { return false }, formatMessage: entry => { return entry.message; }
  },
  sensor_no_scan_error: {
    title: "Pas de passage de contenant",
    hide: () => { return false }, formatMessage: entry => { return entry.message; }
  },
  paper_replaced: {
    title: "Changement du papier dans l'imprimante",
    hide: () => { return false }, formatMessage: entry => { return entry.message; }
  },
  deposit_error: {
    title: "Erreur de dépôt", hide: () => { return false },
    formatMessage: entry => {
      const regex = new RegExp(/Deposit error:( )+Badge ID: ([a-zA-Z0-9]*).*/, 'g');
      const result = regex.exec(entry.message);
      return result && result.length >= 3 ? 'badge: ' + result[2] : entry.message;
    }
  },
  glass_filling_level: {
    title: 'Remplissage', hide: () => { return true },
    formatMessage: entry => {
      const { value, unit } = unitConversion.convertValueAndUnit(parseInt(entry.message), 'mL');
      return formatText.methods.formatNumber(value) + " " + unit;
    }
  },
  plastic_filling_level: {
    title: 'Remplissage', hide: () => { return true },
    formatMessage: entry => {
      const { value, unit } = unitConversion.convertValueAndUnit(parseInt(entry.message), 'mL');
      return formatText.methods.formatNumber(value) + " " + unit;
    }
  },
  bottle_filling_level: {
    title: 'Remplissage', hide: () => { return true },
    formatMessage: entry => {
      const { value, unit } = unitConversion.convertValueAndUnit(parseInt(entry.message), 'mL');
      return formatText.methods.formatNumber(value) + " " + unit;
    }
  },
}

const setMachineStatus = async (machineStatus, commit, setMachineStatusEvent, setDrawersEvent, setDrawersLabelsEvent) => {
  await commit(setMachineStatusEvent, machineStatus)
  // Math.ceil is used to round up the percentage
  if (machineStatus.maxCapacity > 0) {

    /**
     * We don't display glass filling percentage without threshold for now, because this is not supposed
     * to be known by client. 
     */

    const containers = []
    const maxMachineCapacity = machineStatus.drawers.reduce((prev, current) => current.maxCapacity + prev, 0)
    for (const drawer of machineStatus.drawers) {
      for (const containerFromStatus of drawer.containers) {
        let c = containers.find(container => container.name === containerFromStatus.containerName);
        if (!c) {
          c = {
            name: containerFromStatus.containerName,
            data: Array(machineStatus.drawers.length + 1).fill(0),
            units: Array(machineStatus.drawers.length + 1).fill(0)
          }
          containers.push(c)
        }
        // fill drawer number
        c.data[drawer.drawerNumber - 1] += (containerFromStatus.containerCapacity * containerFromStatus.containerNumber * 100) / drawer.maxCapacity
        c.units[drawer.drawerNumber - 1] += containerFromStatus.containerNumber
        // fill total
        c.data[machineStatus.drawers.length] += (containerFromStatus.containerCapacity * containerFromStatus.containerNumber * 100) / maxMachineCapacity
        c.units[machineStatus.drawers.length] += containerFromStatus.containerNumber
      }
    }

    // ceil all values
    containers.map((container) => container.data = container.data.map(value => Math.ceil(value)))

    await commit(setDrawersEvent, containers)
    await commit(setDrawersLabelsEvent, machineStatus.drawers.map(drawer => "Trappe " + drawer.drawerNumber + ''))
  }
}

const actions = {
  async getHistory({ commit }, payload) {
    let url = '/machine/' + payload.machine_id + '/history'
    const res = await api.getData(url)
    if (res.data && res.data.history != null) {
      let history = res.data.history
      history.forEach((h) => {
        if (Object.keys(historyEntries).includes(h.action)) {
          const historyType = historyEntries[h.action];
          h.hide = historyType.hide(h);
          h.message = historyType.formatMessage(h);
          h.action = historyType.title ?? h.action;
          h.component = historyType.component;
        } else {
          h.hide = true;
        }
      });
      history = history.filter(function (entry) {
        return !entry.hide;
      })
      await commit('setHistory', history)
    } else {
      await commit('setHistory', [])
    }
  },
  async getHistoryAtTimestamp({ commit }, payload) {
    await commit('setDrawersAtTimestamp', [])
    await commit('setDrawersLabelsAtTimestamp', [])
    let url = '/machine/' + payload.machine_id
      + '/status-at-time?date=' + moment(payload.date).format('YYYY-MM-DD:HH-mm')

    let res = await api.getData(url)
    if (res.data) {
      setMachineStatus(res.data, commit, 'setMachineStatusAtTimestamp', 'setDrawersAtTimestamp', 'setDrawersLabelsAtTimestamp')
    }
  },
  async getMachineStatus({ commit, rootState }, payload) {
    if (payload.machine_id != null) {
      await commit('setLastGlassDepositDate', null)
      await commit('setDrawers', [])
      await commit('setDrawersLabels', [])
      let machine = rootState.loggeduser.machines.find(machine => {
        return machine.id === payload.machine_id
      });
      let res = {};
      let url = '/machine/internal/' + machine.internalId + '/status'
      res = await api.getData(url)
      if (res.data) {
        const machineStatus = res.data
        await commit('setLastGlassDepositDate', machineStatus.lastEmptied)
        await commit('setMaxCapacityWithThreshold', machineStatus.maxCapacityWithThreshold)
        await commit('setMaxCapacity', machineStatus.maxCapacity)    
        setMachineStatus(machineStatus, commit, 'setMachineStatus', 'setDrawers', 'setDrawersLabels')
      } else {
        await commit('setMachineStatus', {})
        await commit('setLastGlassDepositDate', null)
        await commit('setMaxCapacityWithThreshold', [0])
      }
    }
  },
}

const mutations = {
  setMachineStatus(state, machineStatus) {
    state.machineStatus = machineStatus
  },
  setLastGlassDepositDate(state, lastGlassDepositDate) {
    state.lastGlassDepositDate = lastGlassDepositDate
  },
  setMaxCapacity(state, maxCapacity) {
    state.maxCapacity = maxCapacity
  },
  setMaxCapacityWithThreshold(state, maxCapacityWithThreshold) {
    state.maxCapacityWithThreshold = maxCapacityWithThreshold
  },
  setDrawersLabels(state, labels) {
    state.drawersLabels = labels
  },
  setDrawers(state, drawers) {
    state.drawers = drawers
  },
  setHistory(state, history) {
    state.history = history
  },
  setDeposit(state, deposit) {
    state.deposit = deposit
  },
  setMachineStatusAtTimestamp(state, machineStatusAtTimestamp) {
    state.machineStatusAtTimestamp = machineStatusAtTimestamp
  },
  setDrawersAtTimestamp(state, drawersAtTimestamp) {
    state.drawersAtTimestamp = drawersAtTimestamp
  },
  setDrawersLabelsAtTimestamp(state, drawersLabelsAtTimestamp) {
    state.drawersLabelsAtTimestamp = drawersLabelsAtTimestamp
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}

