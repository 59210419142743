export class ContainerModel {
  constructor () {
    this.id = ''
    this.name = ''
    this.description = ''
    this.supplier_name = ''
    this.supplier_ref = ''
    this.average_deposit = 0
    this.creation_date = ''
    this.barcode_prefix = ''
    this.tare = 0
    this.impact_saved_water = 0
    this.impact_saved_co2_per_kg_plastic = 0
    this.impact_equivalent_plastic_unit_weight = 0
    this.impact_equivalent_plastic_unit_cost = 0
    this.archived = false
  }
}
