import { ContainerModel } from '@/models/ContainerModel'
import apiContainer from '@/utils/backend/container.js'
import apiTag from '@/utils/backend/tag.js'
import Vue from 'vue'

const state = {
  options: {},
  containerModels: [],
  containerModel: {},
  totalContainerModelItems: 1000,
  containerModelsForMachine: {},
  barcodesLikeValue: [],
  containerDetail: {},
}

const getters = {}

const actions = {
  async getAllContainerModels({ commit }, options) {
    let data = await apiContainer.getAllContainerModels(options);
    if (data) {
      await commit('setContainerModels', data.content)
      await commit('setTotalContainerModelItems', data.totalElements)
    }
  },
  async getContainerModelById({ commit }, id) {
    if (id) {
      const containermodel = await apiContainer.getContainerModelById(id);
      await commit('setContainerModel', containermodel)
      await commit('breadcrumbs/replace',
        {
          find: ':containermodel',
          replace: { text: containermodel.name },
        },
        { root: true },
      )
    } else {
      await commit('setContainerModel', { containerModel: new ContainerModel() })
    }
  },
  async getListBarcodesLikeValue({ commit }, barcodeValue) {
    if (barcodeValue) {
      await commit('setBarcodesLikeValue', await apiTag.getListBarcodesLikeValue(barcodeValue))
    } else {
      await commit('setBarcodesLikeValue', { barcodeList: [] })
    }
  },
  async getContainerDetailsByBarcode({ commit }, barcodeValue) {
    if (barcodeValue) {
      let res = await apiTag.getContainerDetailsByBarcode(barcodeValue);
      if (res) {
        await commit('setContainerDetail', res)
      } else {
        await commit('setContainerDetail', { containerDetail: null })
      }
    } else {
      await commit('setContainerDetail', { containerDetail: null })
    }
  },
  async archiveContainerModel({ commit, state }, containerId) {
    try {
      const containerModel = state.containerModels.find(model => model.id === containerId);
      if (!containerModel) {
        throw new Error(`Container model with ID ${containerId} not found`);
      }
      containerModel.archived = true;
      await apiContainer.updateContainerModel(containerModel);
      commit('updateContainerModelStatus', { id: containerId, archived: true });
    } catch (error) {
      console.error("Erreur lors de l'archivage du modèle de conteneur :", error);
      throw error;
    }
  },

  async unarchiveContainerModel({ commit, state }, containerId) {
    try {
      const containerModel = state.containerModels.find(model => model.id === containerId);
      if (!containerModel) {
        throw new Error(`Container model with ID ${containerId} not found`);
      }
      containerModel.archived = false;
      await apiContainer.updateContainerModel(containerModel);
      commit('updateContainerModelStatus', { id: containerId, archived: false });
    } catch (error) {
      console.error("Erreur lors du désarchivage du modèle de conteneur :", error);
      throw error;
    }
  },
  
}

const mutations = {
  setContainerModels(state, containerModels) {
    state.containerModels = containerModels
  },
  setContainerModel(state, containerModel) {
    state.containerModel = containerModel
  },
  setContainerModelsForMachine(state, containerModelsForMachine) {
    state.containerModelsForMachine = containerModelsForMachine
  },
  setTotalContainerModelItems(state, totalContainerModelItems) {
    state.totalContainerModelItems = totalContainerModelItems
  },
  setOptions(state, myoptions) {
    state.options = myoptions
  },
  setBarcodesLikeValue(state, barcodesLikeValue) {
    state.barcodesLikeValue = barcodesLikeValue
  },
  setContainerDetail(state, containerDetail) {
    state.containerDetail = containerDetail
  },
  updateContainerModelStatus(state, { id, archived }) {
    const index = state.containerModels.findIndex(model => model.id === id);
    if (index !== -1) {
      Vue.set(state.containerModels[index], 'archived', archived); // Assurez-vous d'utiliser Vue.set pour la réactivité
    }
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
