import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
// import api from "@/utils/backend-api"
import company from './modules/company'
import user from './modules/user'
import container from './modules/container'
import global from './modules/global'
import breadcrumbs from './modules/breadcrumbs'
import loggeduser from './modules/loggeduser'
import statistics from './modules/statistics'
import machine from './modules/machine'
import config from './modules/config'
Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState({ storage: window.sessionStorage })], // !debug ? [createPersistedState({ storage: window.sessionStorage })] : [],
  modules: {
    config,
    company,
    user,
    container,
    global,
    breadcrumbs,
    loggeduser,
    statistics,
    machine,
  },
})
