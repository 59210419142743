import api from '../backend-api.js';
export default {
    getAllCompanies: async () => {
        return (await api.getData('/company')).data
    },
    createCompany: async (newCompany) => {
        return (await api.postData('/company', newCompany)).data;
    },
    deleteCompany: async (company) => {
        return api.deleteData('/company/' + company.id);
    },
    updateCompany: async (company) => {
        return api.putData('/company/' + company.id, company);
    },
    getCompanyUsers: async (companyId) => {
        return (await api.getData('/company/' + companyId + '/users')).data;
    }
};
