import api from '../backend-api.js';


export default {

    async getListBarcodesLikeValue(barcodeValue) {
        return (await api.getData('/tag/listalike?barcodeValue=' + barcodeValue)).data;
    },
    async getContainerDetailsByBarcode(barcode) {
      let url = '/tag/detail?barcodeValue=' + barcode
      return (await api.getData(url)).data;
    },

};