import auth from '@/utils/auth';
import { Roles } from '@/utils/roles';
import moment from "moment";
import Vue from "vue";
import Router from "vue-router";
import goTo from "vuetify/es5/services/goto";
import apiUser from '@/utils/backend/user.js'


Vue.use(Router);

async function loggedInCheck(to, from, next) {
  await window.Store.commit('loggeduser/initializeStore');
  const loggedUser = auth.getLoggedUser()

  if (auth.loggedIn()) {
    try {
      await window.Store.dispatch("loggeduser/getAllMachinesForUser");
    } catch (e) {
      next({
        path: '/login',
        query: {redirect: to.fullPath},
      })
    }
  }
  if (!auth.loggedIn() || loggedUser.roles === undefined) {
    next({
      path: '/login',
      query: {redirect: to.fullPath},
    })
  }
}

async function displayUnauthorized() {
// role not authorized so redirect to home page
  await window.Store.commit('global/set_snackbar', { display: true, mode: 'error', notice: 'Vous n\'êtes pas autorisé à accéder à cette ressource' })
  // next('/login')
  return false
}

async function requireAuth(to, from, next) {
  await loggedInCheck(to, from, next);

  const loggedUser = auth.getLoggedUser()
  const {authorize} = to.meta

  if (authorize) {
    if (authorize.length && !loggedUser.roles.some(v => authorize.indexOf(v) !== -1)) {
      return await displayUnauthorized();
    } else {
      next()
    }
  }
}

async function requireAuthSoldeConvive(to, from, next) {
  const loggedUser = auth.getLoggedUser()

  await loggedInCheck(to, from, next, loggedUser);

  if(Roles.isAdmin(loggedUser)) {
      next();
  }
  else {
    const facilities = await apiUser.getAllFacilities();
    const hasBalanceEnabled = facilities.some(facility => facility.facilityConfig.balanceEnabled);

    if (hasBalanceEnabled) {
      next();
    } else {
      return await displayUnauthorized();
    }
  }


}


const router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  // This is for the scroll top when click on any router link
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo);
  },
  // This is for the scroll top when click on any router link
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/layouts/full-layout/Layout"),
      beforeEnter: requireAuth,
      children: [
        {
          name: "Tableau de bord",
          path: "/dashboard",
          component: () => import("@/views/noww/dashboard/NowwDashboard"),
          beforeEnter: requireAuth,
          meta: { authorize: [Roles.User] },
        },
        {
          name: "Soldes convives",
          path: "/convives",
          component: () => import("@/views/noww/soldes-convives/SoldesConvives"),
          beforeEnter: requireAuthSoldeConvive,
          meta: { authorize: [Roles.Admin] },
          props() {
            return {
              page: {
                title: "Soldes convives",
              },
              breadcrumbs: [
                {
                  text: "Accueil",
                  disabled: true,
                  to: "#",
                },
                {
                  text: "Soldes convives",
                  disabled: true,
                },
              ],
            }
          },
        },
        {
          name: "Transactions",
          path: "/transactions",
          component: () => import("@/views/noww/transactions/Transactions"),
          beforeEnter: requireAuth,
          meta: { authorize: [Roles.User] },
          props() {
            return {
              page: {
                title: "Transactions",
              },
              breadcrumbs: [
                {
                  text: "Accueil",
                  disabled: false,
                  to: "#",
                },
                {
                  text: "Transactions",
                  disabled: true,
                },
              ],
            }
          },
        },
        {
          path: "/transactions/add",
          component: () => import("@/views/noww/transactions/add/AddTransaction"),
          beforeEnter: requireAuth,
          meta: { authorize: [Roles.User] },
          props() {
            return {
              page: {
                title: "Gestion des contenants",
              },
              breadcrumbs: [
                {
                  text: "Accueil",
                  disabled: false,
                  to: "#",
                },
                {
                  text: "Gestion des contenants",
                  disabled: true,
                },
              ],
            }
          }
        },
        {
          path: "/container/range",
          component: () => import("@/views/noww/attribution/Attribution"),
          beforeEnter: requireAuth,
          meta: { authorize: [Roles.User] },
        },
        {
          path: "/container/detail",
          component: () => import("@/views/noww/container/ContainerDetail"),
          beforeEnter: requireAuth,
          meta: { authorize: [Roles.User] },
        },
        {
          name: "machine",
          path: "/machine/",
          component: () => import("@/views/noww/machine/Machine"),
          beforeEnter: requireAuth,
          meta: { authorize: [Roles.User] },
        },
        {
          name: 'machinefleetmanagement',
          path: "/machine/:id/fleet-management",
          component: () => import("@/views/noww/machine/MachineFleetManagement"),
          beforeEnter: requireAuth,
          meta: { authorize: [Roles.User] },
        },
        {
          name: 'containermodels',
          path: "/container/catalog",
          component: () => import("@/views/noww/container/ContainersCatalog"),
          beforeEnter: requireAuth,
          meta: { authorize: [Roles.User] },
        },
        {
          name: 'containermodel',
          path: "/container/catalog/inspect/:id",
          component: () => import("@/views/noww/container/ContainerView"),
          beforeEnter: requireAuth,
          meta: { authorize: [Roles.Admin] },
          props(route) {
            const props = { ...route.params }
            props.id = +props.id
            return props
          },
        },
        {
          name: 'Ajout modèle contenant',
          path: "/container/catalog/add",
          component: () => import("@/views/noww/container/ContainerView"),
          beforeEnter: requireAuth,
          meta: { authorize: [Roles.Admin] },
        },
        {
          name: "reporting",
          path: "reporting/",
          component: () => import("@/views/noww/reporting/Reporting"),
          beforeEnter: requireAuth,
          meta: { authorize: [Roles.User] },
        },
        {
          name: "alerts",
          path: "alerts/",
          component: () => import("@/views/noww/alerts/Alerts"),
          beforeEnter: requireAuth,
          meta: { authorize: [Roles.Admin] },
        },
        {
          name: 'report-display',
          path: "reporting/:reportConfigurationId",
          component: () => import("@/views/noww/reporting/ReportDisplay"),
          beforeEnter: requireAuth,
          meta: { authorize: [Roles.User] },
          props(route) {
            const props = { ...route.params, ...route.query }
            props.startReport = moment(props.startReport);
            props.endReport = moment(props.endReport);
            props.reportConfigurationId = Number.parseInt(props.reportConfigurationId);
            return props
          },
        },
        {
          name: 'admin-company',
          path: "admin/company",
          component: () => import("@/views/noww/admin/company/Company"),
          beforeEnter: requireAuth,
          meta: { authorize: [Roles.Admin] },
        },
        {
          name: 'admin-user',
          path: "admin/user",
          component: () => import("@/views/noww/admin/user/Users"),
          beforeEnter: requireAuth,
          meta: { authorize: [Roles.Admin] },
        },
        {
          name: 'admin-facility',
          path: "admin/facilities",
          component: () => import("@/views/noww/admin/facility/Facility"),
          beforeEnter: requireAuth,
          meta: { authorize: [Roles.Admin] },
        },
        {
          name: 'admin-machine',
          path: "admin/machine",
          component: () => import("@/views/noww/admin/machine/Machines"),
          beforeEnter: requireAuth,
          meta: { authorize: [Roles.Admin] },
        },
      ],
    },
    {
      name: "dematerialized-refund",
      path: "/refund",
      component: () => import("@/views/noww/dematerialized-refund/DematerializedRefund"),
    },
    {
      path: "/login",
      component: () => import("@/views/noww/authentication/Login"),
    },
    {
      path: "/resetpassword",
      component: () => import("@/views/noww/authentication/ResetPassword"),
    },
    {
      path: "/resetpassword/confirm",
      component: () => import("@/views/noww/authentication/ResetPasswordConfirm"),
    },
    {
      name: 'logout',
      path: '/logout',
      beforeEnter(to, from, next) {
        auth.logout()
        next({
          path: '/login',
          query: { redirect: from.fullPath },
        })
      },
    },
    {
      path: "*",
      component: () => import("@/views/authentication/Error"),
    },



  ],
});

export default router;
