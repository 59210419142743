import axios from 'axios'

const BASE_URL = process.env.VUE_APP_BASE_URL_API + process.env.VUE_APP_VENDING_API

const instance = axios.create({
  baseURL: BASE_URL,
  timeout: false,
  params: {}, // do not remove this, its added to add params later in the config
})

// Add a request interceptor
instance.interceptors.request.use(function (config) {
  /* global  Store */
  let token = Store.state.loggeduser.user.accessToken
  if (token) {
    config.headers.Authorization = 'Bearer ' + token
  }
  return config
}, function (error) {
  // Do something with request error
  return Promise.reject(error)
})

instance.interceptors.response.use((response) => {
  return response
}, async (error) => {
  try {
    this.$store.commit('global/set_snackbar', { display: true, mode: 'error', notice: 'Erreur lors de la récupération' })
  } catch (e) {
    // we do nothing
  }
  if (typeof error.response !== 'undefined' && error.response.status == 401 && window.location.hash.indexOf("login") < 0) {
    await Store.dispatch('loggeduser/logout');
    window.location.assign((process.env.BASE_URL ?? '') + "/#/login?redirect=" + window.location.hash.replace("#", ""));
  }
  return Promise.reject(error)
})

export default {
  getUrl(relativeURI) {
    return BASE_URL + relativeURI;
  },
  getData(action, config) {
    let url = `${BASE_URL}`
    url += action
    return instance.get(url, config)
  },
  postData(action, data, config) {
    let url = `${BASE_URL}`
    url += action
    return instance.post(url, data, config)
  },
  putData(action, data, config) {
    let url = `${BASE_URL}`
    url += action
    return instance.put(url, data, config)
  },
  deleteData(action, data, config) {
    let url = `${BASE_URL}`
    url += action
    return instance.delete(url, {...config, data: data})
  },
  login(action, data) {
    let url = `${BASE_URL}`
    url += action
    return instance.get(url, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
  },
}
