import Quantity from 'js-quantities';

const units = {
    'volume': {
        'm^3': 'm³',
        'L': 'L',
        'mL': 'mL'
    },
    'mass': {
        'tonne': 'tonnes(s)',
        'kg': 'kg',
        'g': 'g'
    },
    'currency': {
        'USD': '€',
        'cents': 'centimes'
    },
    'unitless': {
        '%': '%'
    }
}

function process(value, unit, precision, targetUnit) {
    let newValue = Quantity.swiftConverter(unit, targetUnit)(value);
    newValue = new Quantity(newValue, targetUnit).toPrec(precision).toString()
    return { value: newValue.split(' ')[0], unit: targetUnit };
}

export default {
    convertValueAndUnit: function (value, unit, precision = 0.01) {
        if (unit === '€') {
            unit = 'USD';
        } else if (unit === 'centimes') {
            unit = 'cents';
        }
        try {
            let unitConverter = new Quantity(value, unit);
            
            if (value === 0) {
                return { value: 0, unit: units[unitConverter.kind()][Object.keys(units[unitConverter.kind()])[0]] };
            } else if (value < 1) {
                console.log(value, units[unitConverter.kind()][Object.keys(units[unitConverter.kind()])[0]]);
                return { ...process(value, unit, precision, unit), unit: units[unitConverter.kind()][Object.keys(units[unitConverter.kind()])[0]] };
            } else {
                for (const targetUnitIndex in units[unitConverter.kind()]) {
                    let result = process(value, unit, precision, targetUnitIndex);
                    if (result.value >= 1) {
                        return { ...result, unit: units[unitConverter.kind()][targetUnitIndex] };
                    }
                }
            }

        } catch (e) {
            console.log("Souci de gestion d'unité", value, unit);
        }
        return { value: value, unit: unit };
    }
}
