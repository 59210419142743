const formatterWithDecimals = new Intl.NumberFormat("fr-FR", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});
const formatterWithoutDecimals = new Intl.NumberFormat("fr-FR", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});

export default {
    methods: {
        shortenText(str) {
            return str === str.substring(0,20) ? str : str.substring(0, 20) + "...";
        },
        formatNumber(x) {
            let value = Number(('' + x).replace(',', '.'));
            if (isNaN(value)) {
                return x;
            } else {
                if (Number.isInteger(value)) {
                    return formatterWithoutDecimals.format(value);
                }
                return formatterWithDecimals.format(value);
            }
        },
        deformatNumber(x) {
            return Number(('' + x).replace(',', '.'));
        },
    }
}
