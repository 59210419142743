<template>
    <div>
        <v-dialog>
            <template v-slot:activator="{ on, attrs }">
                <v-btn @click="loadEmptyMachineDeposits()" regular color="primary" v-bind="attrs" v-on="on">
                    <v-icon left>mdi-archive-outline</v-icon>
                    Contenu de la machine au vidage
                </v-btn>
            </template>
            <template v-slot:default="dialog">
                <v-card v-if="machineStatus">
                    <v-toolbar color="primary" dark>
                        Rendus entre le {{ emptyingDateFormatted(machineStatus.lastEmptied) }} et le {{
                emptyingDateFormatted(machineAction.date) }}
                    </v-toolbar>
                    <v-card-text>
                        <MachineFillingLevel :labels="drawersLabels" :series="drawers">
                        </MachineFillingLevel>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                        <v-btn text @click="dialog.value = false"><v-icon>mdi-window-close</v-icon></v-btn>
                    </v-card-actions>
                </v-card>
            </template>
        </v-dialog>
    </div>
</template>

<script>
import { formatDate } from "@/utils/dates";
import { mapState } from "vuex";

export default {
    name: "EmptyMachineHistory",
    props: {
        machineAction: {
            type: Object,
            required: true,
        },
    },
    components: {
        MachineFillingLevel: () => import("@/views/noww/machine/components/MachineFillingLevel"),
    },
    computed: {
        ...mapState("machine", {
            drawers: "drawersAtTimestamp",
            drawersLabels: "drawersLabelsAtTimestamp",
            machineStatus: "machineStatusAtTimestamp",
        }),
    },
    methods: {
        async loadEmptyMachineDeposits() {
            try {
                await this.$store.dispatch("machine/getHistoryAtTimestamp", {
                    machine_id: this.machineAction.machineId,
                    date: this.machineAction.date,
                });
            } catch (e) {
                console.log(e);
                this.$store.commit("global/set_snackbar", {
                    display: true,
                    mode: "error",
                    notice: "Impossible de récupérer les dépôts",
                });
            }
        },
        emptyingDateFormatted(emptyingDate) {
            return formatDate(emptyingDate, "DD/MM/YYYY [à] HH[h]mm")
        },
    }
}
</script>