import api from '../../utils/backend-api'
import apiCompany from '@/utils/backend/company.js'
import { Company } from '../../models/Company'

// import { get } from 'lodash'

const state = {
  company: new Company(),
  items: []
}

const getters = {}

const actions = {
  async getAllCompanies ({ commit }) {
    let companies = await apiCompany.getAllCompanies();
    await commit('setItems', companies);
  },
  getCompanyById ({ commit }, id) {
    commit('setLoading', { loading: true })
    if (id) {
      api.getData('/company/' + id).then(
        res => {
          const company = res.data
          commit('setCompany', company)
          commit('breadcrumbs/replace',
            {
              find: ':company',
              replace: { text: company.name },
            },
            { root: true },
          )
        },
        // err => {
        //   console.log(err)
        // },
      )
    } else {
      commit('setCompany', { company: new Company() })
    }
  },
}

const mutations = {
  setOrderList (state, orders) {
    state.orders = orders
  },
  setItems (state, companies) {
    state.items = companies
  },
  setCompany (state, company) {
    state.company = company
  },
  replaceBreadcrumb (state, payload, rootState) {
    rootState.breadcrumbs.breadcrumbs = payload
  },
  setPagination (state, pagination) {
    state.pagination = pagination
  },
  setLoading (state, { loading }) {
    state.loading = loading
  },
  setNotice (state, { notice }) {
    state.notice = notice
  },
  setSnackbar (state, { snackbar }) {
    state.snackbar = snackbar
  },
  setMode (state, { mode }) {
    state.mode = mode
  },
  setCustomer (state, { customer }) {
    state.customer = customer
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
