/* globals Store */
import api from './backend-api'

export default {
  login(username, pass, cb) {
    const data = { username: username, password: pass }
    api.postData('/auth/signin', data).then(async (res) => {
      let user = res.data
      await Store.dispatch('loggeduser/updateUser', { user })
      await Store.dispatch("loggeduser/getAllMachinesForUser");
      if (cb) {
        return cb(true, null);
      }
    }, (err) => {
      if (cb) {
        return cb(false, err)
      }
    })
  },
  getToken() {
    return Store.state.loggeduser.user.accessToken
  },
  getLoggedUser() {
    return Store.state.loggeduser.user
  },
  async logout(cb) {
    // delete localStorage.token
    await Store.dispatch('loggeduser/logout')
    if (cb) {
      return cb(false)
    }
  },
  loggedIn() {
    return !!Store.state.loggeduser.user
  },
  sendVerificationCode(email, cb) {
    // console.log("sendCode"+email)
    cb = arguments[arguments.length - 1]
    // console.log(cb)
    api.postData('/auth/password/sendresetcode', {email}).then((res) => {
      if (cb) {
        cb(res, null)
      }
    }, (err) => {
      if (cb) {
        cb(null, err)
      }
    })
  },
  modifyPassword(data, cb) {
    // console.log("sendCode"+email)
    cb = arguments[arguments.length - 1]
    api.postData('/auth/password/modify', data).then((res) => {
      if (cb) {
        cb(res, null)
      }
    }, (err) => {
      if (cb) {
        cb(null, err)
      }
    })
  },
}
