import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store/index";
import Vuebar from "vuebar";
import "./plugins/base";
import api from './utils/backend-api'
import moment from "moment";

moment.locale(process.env.VUE_APP_I18N_LOCALE)

Vue.config.productionTip = false;
Vue.use(Vuebar);
window.Store = store
Vue.prototype.api = api
new Vue({
  vuetify,
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
