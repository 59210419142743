export class Company {
  constructor () {
    this.id = ''
    this.name = ''
    this.address = ''
    this.iscaterer = false
    this.iscustomercompany = false
    this.isretailer = false
  }
}
