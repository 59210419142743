import Vue from 'vue'
import Vuex from 'vuex'
import api from '../../utils/backend-api'

Vue.use(Vuex)

const state = {
  dashboardStatistics: {},
  refundModesSeries: {},
  transactionsByDatesSeries: {},
  amountByDatesSeries: {},
}

let abortControllers = {};

const actions = {
  async getTransactions({ commit }, payload) {
    let data = await generateRequests(payload, '/statistics/transactions')

    await commit('setTransactionsByDatesSeries', data);
  },
  async getRefundModes({ commit }, payload) {
    let data = await generateRequests(payload, '/statistics/refundModes')

    await commit('setRefundModesSeries', data)
  },
  async getDepositAmount({ commit }, payload) {
    let data = await generateRequests(payload, '/statistics/amount')
    await commit('setAmountByDatesSeries', data)
  },
  async getDashboardStatistics({ commit }, payload) {
    const baseUrl = '/statistics/dashboard'
    if (abortControllers[baseUrl]) {
      abortControllers[baseUrl].abort();
    }
    abortControllers[baseUrl] = new AbortController();

    let url = baseUrl
    url = processDatesAndFacilitiesForRequestUrl(payload, url);

    let res;
    res = await api.getData(url, { signal: abortControllers[baseUrl].signal })
    if (res && res.data) {
      const statistics = res.data
      await commit('setDashboardStatistics', statistics)
    } else {
      await commit('setDashboardStatistics', {})
    }
  },
}

const mutations = {
  setTransactions(state, transactions) {
    state.transactions = transactions
  },
  setRefundModesSeries(state, refundModesSeries) {
    state.refundModesSeries = refundModesSeries
  },
  setTransactionsByDatesSeries(state, transactionsByDatesSeries) {
    state.transactionsByDatesSeries = transactionsByDatesSeries
  },
  setAmountByDatesSeries(state, amountByDatesSeries) {
    state.amountByDatesSeries = amountByDatesSeries
  },
  setDashboardStatistics(state, dashboardStatistics) {
    state.dashboardStatistics = dashboardStatistics
  },
}

async function generateRequests(payload, baseUrl) {
  if (abortControllers[baseUrl]) {
    abortControllers[baseUrl].abort();
  }
  abortControllers[baseUrl] = new AbortController();

  let data = {};
  await Promise.all(payload.steps.map(async (step) => {
    let url = processDatesAndFacilitiesForRequestUrl(payload, baseUrl, step)

    try {
      let res = await api.getData(url, { signal: abortControllers[baseUrl].signal })
      if (res.data && (typeof res.data.length === 'undefined' || res.data.length > 0)) {
        data[step] = res.data
      }
    } catch (e) {
      // do nothing if no data can be retrieve (cancelled or not)
    }
  }));
  return data;
}

function processDatesAndFacilitiesForRequestUrl(payload, url, step) {
  if (typeof step !== 'undefined') {
    url += '?step=' + step + '&'
  } else {
    url += '?';
  }
  if (payload.dates) {
    if (payload.dates[0]) {
      url += 'start_date=' + payload.dates[0]
    }
    if (payload.dates[1]) {
      url += '&end_date=' + payload.dates[1]
    } else {
      url += '&end_date=' + payload.dates[0]
    }
  }
  console.log("statistics -> facilities", payload.facilities)
  if (payload.facilities) {
    for (const element of payload.facilities.filter(f => f != undefined)) {
      url += '&facilities=' + element
    }
  }
  return url
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
